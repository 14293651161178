<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
        >
            <template slot="left_btn">
                <a-button @click="add()" type="primary">添加监管</a-button>
            </template>

            <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.supervise/exportSupervise"
                ></export-table>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="see(data.record, 'add')">查看</a>
                <a-divider type="vertical" />
                <a
                    @click="edit(data.record)"
                    v-if="data.record.status == '未处理'"
                    >编辑</a
                >
                <a-divider type="vertical" />
                <a-popconfirm
                    title="您确定要删除吗?"
                    v-if="data.record.status == '未处理'"
                    @confirm="del(data.record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import {
    getSuperviseList, delSupervise
} from "@/api/cost";

let columns = [{
    title: "监管时间",
    dataIndex: "create_time",
},
{
    title: "监管人",
    dataIndex: "username",
},
{
    title: "监管客户ID",
    dataIndex: "customer_id",
},
{
    title: "客户名称",
    dataIndex: "name",
},
{
    title: "监管类型",
    dataIndex: "type",
},
{
    title: "处理结果",
    dataIndex: "status",
},
{
    title: "监管说明",
    dataIndex: "reason",
},
{
    title: "操作",
    scopedSlots: {
        customRender: "operation"
    }
},
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: getSuperviseList,
            columns,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: 'select',
                        name: 'type',
                        title: '监管类型',
                        list: [{
                            key: 'reward',
                            value: '奖励'
                        },
                        {
                            key: 'punishment',
                            value: '惩罚'
                        },
                        {
                            key: 'normal',
                            value: '正常'
                        }
                        ],
                        options: {},
                    },
                    {
                        type: 'select',
                        name: 'status',
                        title: '处理结果',
                        list: [{
                            key: 0,
                            value: '未处理'
                        },
                        {
                            key: 1,
                            value: '已处理'
                        }
                        ],
                        options: {},
                    },
                    {
                        type: "text",
                        name: "supervise_name",
                        title: "监管人",
                        placeholder: '输入监管人、手机号',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "监管客户",
                        placeholder: '输入客户名称、ID、地址',
                        options: {

                        }
                    },

                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo,
                supervision_ids: ''
            },

        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
    },

    methods: {
        edit(key) {
            this.$router.push("/cost/supervision/edit?id=" + key.id);
        },
        del(data) {
            delSupervise({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        add() {
            this.$router.push("/cost/supervision/edit");
        },
        see(data) {
            this.$router.push("/cost/supervision/edit?type=see&id=" + data.id);
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>
